
import { Vue, Component } from 'vue-property-decorator'
import BaseModal from '~/components/molecules/base-modal.vue'
import Animation from '~/components/molecules/animation.vue'

@Component({
  components: { BaseModal, Animation }
})
export default class LoadingModal extends Vue {
  coreProps = {
    canCancel: ['x'],
    active: false,
    width: 753
  }

  get isDark() {
    return this.$store.state.theme.color === 'dark'
  }

  show() {
    this.coreProps.active = true
  }

  hide() {
    this.coreProps.active = false
  }
}
