
import { Vue, Component } from 'vue-property-decorator'
import socials from '~/data/socials.json'

@Component({
  components: {}
})
export default class Footer extends Vue {
  imageSrc: string
  data() {
    return {
      imageSrc: process.env.IMAGE_FOLDER
    }
  }

  get isDark() {
    return this.$store.state.theme.color === 'dark'
  }

  get socialIcons() {
    return socials.map(social => ({
      ...social,
      icon: `${this.imageSrc}socials/dark/${social.icon}`
    }))
  }

  get pathName() {
    return this.$nuxt.$route.name
  }
}
