export interface ThemeState {
  color: string
}

export default {
  namespaced: true,
  state: (): ThemeState => ({
    color: 'dark'
  }),
  mutations: {
    setTheme(state: any, color: string) {
      state.color = color
    }
  },
  actions: {
    toggleTheme(context: any) {
      context.commit('setTheme')
    },
    setTheme(context: any, color: any) {
      context.commit('setTheme', color)
    }
  }
}
