
import { Vue, Component } from 'vue-property-decorator'
import FancyButton from '~/components/atoms/fancy-button.vue'

@Component({
  components: {
    FancyButton
  }
})

export default class Error404 extends Vue {}
