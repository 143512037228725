
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch, Prop } from 'vue-property-decorator'
import { dropdown, nav } from '~/data/nav.js'
import ThemeSelector from '~/components/atoms/theme-selector.vue'
import Particle from '~/components/atoms/particle.vue'
import LoadingModal from '~/components/molecules/loading-modal.vue'
import Footer from '~/components/Footer.vue'

@Component({
  components: { ThemeSelector, Particle, 'v-footer': Footer, LoadingModal }
})
export default class Default extends Vue {
  @Prop({ default: true }) layoutMargins: boolean
  nav = nav
  dropdown = dropdown
  mountedHack = false
  showToTopBtn = false
  singleActive = false
  realPath = ''
  imageSrc = process.env.IMAGE_FOLDER
  @Ref('loadingModal') readonly loadingModal!: LoadingModal
  $refs!: {
    loadingModal: HTMLFormElement
  }

  findNameFirstPart(name: string) {
    const parts = name.split(' ')
    return parts.length === 1 ? parts[0] : parts[0] + parts[parts.length - 1]
  }

  findName(name: string) {
    const parts = name.split(' ')
    return parts[parts.length - 1]
  }

  get currentPath() {
    return this.$route.path
  }

  get isDark() {
    return this.$store.state.theme.color === 'dark'
  }

  get isLoading() {
    return this.$store.state.general.loading
  }

  created() {
    this.routeChecker()
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      if (!localStorage.theme) {
        document.documentElement.classList.add('dark')
        this.$store.commit('theme/setTheme', 'dark')
        localStorage.setItem('theme', 'dark')
      }
    }
  }

  mounted() {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      window.addEventListener('scroll', this.handleScrollEvent)
      this.checkTheme()
      setTimeout(() => {
        this.mountedHack = true
      })
    }
  }

  setLang(lang: string) {
    if (localStorage.getItem('lang') === '') {
      localStorage.lang = lang
    } else {
      localStorage.setItem('lang', lang)
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScrollEvent)
  }

  handleScrollEvent() {
    const appHeight =
      document.documentElement?.querySelector('#app')?.clientHeight
    const button = document.querySelector('.to-top-btn')
    if (appHeight) {
      if (
        document.documentElement.scrollTop >=
        appHeight - 120 - document.documentElement.offsetHeight
      ) {
        button?.classList.add('finished')
      } else if (
        button?.classList.contains('finished') &&
        document.documentElement.scrollTop <
          appHeight - 120 - document.documentElement.offsetHeight
      ) {
        button?.classList.remove('finished')
      }
    }
    this.showToTopBtn =
      document.body.scrollTop > 550 || document.documentElement.scrollTop > 550
  }

  handleChangeTheme() {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      if (this.isDark) {
        localStorage.setItem('theme', 'light')
      } else {
        localStorage.setItem('theme', 'dark')
      }
      this.checkTheme()
    }
  }

  get Lang() {
    if (this.$i18n.locale === 'en') {
      return ''
    }
    return this.$i18n.locale
  }

  acitveLinkProgress(name: string, path: string) {
    this.realPath = path + name
    this.singleActive = true
    console.log('working')
  }

  routeChecker() {
    if (this.$route.path === '/') {
      this.singleActive = false
      this.realPath = this.$route.path
    } else {
      this.singleActive = true
      this.realPath = this.$route.path
    }
  }

  checkTheme() {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      if (
        localStorage.theme === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark')
        this.$store.commit('theme/setTheme', 'dark')
      } else {
        setTimeout(() => {
          document.documentElement.classList.remove('dark')
        })
        this.$store.commit('theme/setTheme', 'light')
      }
    }
  }

  handleScroll(goTo: string, singleActive = false, path = '', name = '') {
    if (path + name !== '') {
      this.realPath = path + name
    } else {
      this.realPath = path
    }
    this.singleActive = singleActive
    if (goTo === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      setTimeout(() => {
        document.querySelector(goTo)?.scrollIntoView({
          behavior: 'smooth'
        })
      }, 100)
    }
  }

  @Watch('isLoading')
  loadingWatcher(value: boolean) {
    if (value) {
      this.loadingModal.show()
    } else {
      this.loadingModal.hide()
    }
  }
}
