
import { Vue, Component, Prop } from 'vue-property-decorator'

type canCancelType = 'escape' | 'x' | 'outside'

interface CoreProps {
  canCancel: canCancelType | canCancelType[];
  active: boolean;
  class?: string;
  width?: number;
}

@Component
export default class BaseModal extends Vue {
  @Prop({
    required: true,
  default: {
      canCancel: ['escape', 'outside'],
      active: false
    }
  }) coreProps!: CoreProps

  @Prop({ default: '' }) header: string
  @Prop({ default: '' }) subheader: string
  @Prop({ default: '' }) cardBlockClass: string
  @Prop({ default: '' }) mainBlockClass: string
  @Prop({ default: '' }) contentBlockClass: string

  close() {
    this.coreProps.active = false
  }

  get isDark() {
    return this.$store.state.theme === 'dark'
  }
}
