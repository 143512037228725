
import { computed, useStore } from '@nuxtjs/composition-api'

export default {
  setup() {
    const store = useStore<any>()
    const color = computed(() => {
      return store.state.theme.color === 'dark' ? '#8000FF' : '#FFC0CB'
    })
    return {
      color
    }
  }
}

