import { Toast, Navbar, Carousel, Collapse, Modal } from 'buefy'
import Vue from 'vue'

Vue.use(Navbar)
Vue.use(Carousel)
Vue.use(Toast)
Vue.use(Modal)
Vue.use(Collapse)


// icons
import Icon from 'vue-awesome/components/Icon'

import 'vue-awesome/icons/arrow-up'
import 'vue-awesome/icons/flag'
import 'vue-awesome/icons/beer'
import 'vue-awesome/icons/lightbulb'
import 'vue-awesome/icons/moon'
import 'vue-awesome/icons/angle-right'
import 'vue-awesome/icons/angle-left'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/volume-off'
import 'vue-awesome/icons/volume-up'

Vue.component('v-icon', Icon)