export interface GeneralState {
  loading: boolean
}

export default {
  namespaced: true,
  state: (): GeneralState => ({
    loading: false
  }),
  mutations: {
    setLoading(state: any, loading: any) {
      state.loading = loading
    }
  },
  actions: {
    setLoading(context: any, loading: any) {
      context.commit('setLoading', loading)
    }
  }
}
