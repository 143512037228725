
import { useStore, computed } from '@nuxtjs/composition-api'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    lottery: {
      type: Boolean,
      required: false,
      default: false
    },
    confirm: {
      type: Boolean,
      required: false,
      default: false
    },
    lotteryHome: {
      type: Boolean,
      required: false,
      default: false
      },
      textAbel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const store = useStore<any>()
    const isDark = computed(() => {
      return store.state.theme.color === 'dark'
    })
    return {
      isDark
    }
  }
}
