import axios from 'axios'
export default function (_, inject) {
  const api = axios.create({
    baseURL: process.env.API_URL,
    headers: {
      common: {
        Accept: 'text/plain, application/json, */*'
      }
    }
  })

  inject('api', api)
}
