export const dropdown = {
  display: "home",
  path: "/",
  childs: [
    {
      path: "/",
      display: "All-In-One App",
      scrollTo: "#solution",
    },
    {
      path: "/",
      display: "buy",
      scrollTo: "#buy",
    },
    {
      display: "team",
      path: "/",
      scrollTo: "#team",
    },
    {
      display: "Ridotto News",
      path: "/",
      scrollTo: "#news",
    },
    {
      display: "Token model",
      path: "/",
      scrollTo: "#token",
    },
    {
      display: "whitepaper",
      scrollTo: "#whitepaper",
      path: "/",
    },
    {
      display: "Roadmap",
      scrollTo: "#roadmap",
      path: "/",
    },
    {
      display: "Join community",
      scrollTo: "#community",
      path: "/",
    },
    {
      display: "Our partners",
      scrollTo: "#partners",
      path: "/",
    },
    {
      display: "Our contributors",
      path: "/",
      scrollTo: "#contributors",
    },
    {
      display: "Newsletter",
      path: "/",
      scrollTo: "#newsletter",
    },
  ],
};

export const nav = [
  {
    display: "whitepaper",
    path: "/",
    name: "whitepaper",
    realPath: "/whitepaper",
    scrollTo: "#whitepaper",
  },
  {
    display: "blog",
    name: "blog",
    scrollTo: "#news",
    realPath: "/blog",
    path: "/",
  },
  {
    display: "nft farming",
    name: "nft",
    path: "/nft",
  },
  {
    display: "staking",
    name: "staking",
    path: "/staking",
  },
  {
    display: "bridge",
    name: "bridge",
    path: "/bridge",
  },
];
