
import { computed, useStore } from '@nuxtjs/composition-api'
export default {
  setup() {
    const store = useStore<any>()
    const isDark = computed(() => {
      return store.state.theme.color === 'dark'
    })
    return {
      isDark
    }
  }
}
